import axios from 'axios';
import { HTTP_TOKEN } from '@/assets/js/http-client';

export default {
  actions: {
    getSettings({ commit }) {
      commit('startLodaing');
      axios
        .create({
          headers: {
            Authorization: `Bearer ${localStorage.getItem('token')}`,
          },
        })
        .get(`/admin/settings/`)
        .then(response => {
          commit('setSettings', response.data.data[0]);
          commit('stopLoading');
        })
        .catch(error => {
          console.log(error);
          const message = 'Не удалось загрузить настройки';
          this.$app.$options.toast({
            message,
            title: 'Ошибка',
            type: 'error',
            timeout: 6000,
          });
          commit('stopLoading');
        });
    },
    saveSettings({ commit, rootState }) {
      commit('startLodaing');
      HTTP_TOKEN.post(`/admin/update-settings/`, rootState.settings)
        .then(() => {
          const message = 'Настройки обновлены';
          this.$app.$options.toast({
            message,
            title: 'Успех',
            type: 'success',
            timeout: 6000,
          });
          commit('stopLoading');
        })
        .catch(error => {
          console.log(error);
          const message = 'Не удалось обновить настройки';
          this.$app.$options.toast({
            message,
            title: 'Ошибка',
            type: 'error',
            timeout: 6000,
          });
          commit('stopLoading');
        });
    },
  },
};
