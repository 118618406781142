import axios from 'axios';

export const HTTP = axios.create({
  baseURL: 'https://api.pokerham.io',
});

export const HTTP_TOKEN = axios.create({
  baseURL: 'https://api.pokerham.io',
  headers: {
    Authorization: `Bearer ${localStorage.getItem('token')}`,
  },
});
