<template>
  <ul class="breadcrumb">
    <li v-for="(breadcrumb, key) in activePath" :key="key">
      <div>
        <router-link
          :class="[
            'breadcrumb-link',
            { 'breadcrumb-link--last': key === lastImdex },
          ]"
          :to="fullPathForBradcrumb(breadcrumb)"
        >
          {{ labelBreadcrumb(breadcrumb) }}
        </router-link>
        <svg
          v-if="key !== lastImdex"
          width="6"
          height="12"
          viewBox="0 0 6 12"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M0.164 11.7L4.124 0.395999H5.168L1.208 11.7H0.164Z"
            fill="#BEBEBE"
          />
        </svg>
      </div>
    </li>
  </ul>
</template>

<script>
import { RusLabelPage } from '@/constants/PageName';
export default {
  name: 'Breadcrumbs',
  data() {
    return {
      RusLabelPage,
    };
  },
  methods: {
    labelBreadcrumb(breadcrumb) {
      return this.RusLabelPage.filter(
        pageName => pageName.route === breadcrumb,
      )[0].label;
    },
    fullPathForBradcrumb(breadcrumb) {
      let fullPath = '/';
      for (let i = 0; i < this.activePath.indexOf(breadcrumb) + 1; i++) {
        fullPath += `${this.activePath[i]}/`;
      }
      return fullPath;
    },
  },
  computed: {
    lastImdex() {
      return this.activePath.length - 1;
    },
    activePath() {
      const Bredcrumbs = [
        ...this.$route.path.split('/').filter(p => {
          return p != '';
        }),
      ];
      return Bredcrumbs.filter(path => !path.includes('='));
    },
  },
};
</script>

<style lang="scss" scoped></style>
