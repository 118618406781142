<template>
  <ul class="sidebar">
    <li>
      <router-link class="sidebar-link sidebar-logo" to="/home/">
        <Logo class="auth-logo" />
      </router-link>
    </li>
    <li>
      <router-link
        :class="[
          'sidebar-link',
          { 'sidebar-link--active': activePath === PAGE_USERS },
        ]"
        to="/home/users/"
      >
        <svg
          width="24"
          height="24"
          viewBox="0 0 24 24"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <mask
            id="mask0_1056_1723"
            style="mask-type: alpha"
            maskUnits="userSpaceOnUse"
            x="0"
            y="0"
            width="24"
            height="24"
          >
            <rect width="24" height="24" fill="#D9D9D9" />
          </mask>
          <g mask="url(#mask0_1056_1723)">
            <path
              d="M1 20V17.2C1 16.6333 1.146 16.1123 1.438 15.637C1.72933 15.1623 2.11667 14.8 2.6 14.55C3.63333 14.0333 4.68333 13.6457 5.75 13.387C6.81667 13.129 7.9 13 9 13C10.1 13 11.1833 13.129 12.25 13.387C13.3167 13.6457 14.3667 14.0333 15.4 14.55C15.8833 14.8 16.2707 15.1623 16.562 15.637C16.854 16.1123 17 16.6333 17 17.2V20H1ZM16.65 13.15C17.5 13.25 18.3 13.4207 19.05 13.662C19.8 13.904 20.5 14.2 21.15 14.55C21.75 14.8833 22.2083 15.254 22.525 15.662C22.8417 16.0707 23 16.5167 23 17V20H19V17C19 16.2667 18.796 15.5623 18.388 14.887C17.9793 14.2123 17.4 13.6333 16.65 13.15ZM9 12C7.9 12 6.95833 11.6083 6.175 10.825C5.39167 10.0417 5 9.1 5 8C5 6.9 5.39167 5.95833 6.175 5.175C6.95833 4.39167 7.9 4 9 4C10.1 4 11.0417 4.39167 11.825 5.175C12.6083 5.95833 13 6.9 13 8C13 9.1 12.6083 10.0417 11.825 10.825C11.0417 11.6083 10.1 12 9 12ZM19 8C19 9.1 18.6083 10.0417 17.825 10.825C17.0417 11.6083 16.1 12 15 12C14.8167 12 14.5833 11.9793 14.3 11.938C14.0167 11.896 13.7833 11.85 13.6 11.8C14.05 11.2667 14.3957 10.675 14.637 10.025C14.879 9.375 15 8.7 15 8C15 7.3 14.879 6.625 14.637 5.975C14.3957 5.325 14.05 4.73333 13.6 4.2C13.8333 4.11667 14.0667 4.06233 14.3 4.037C14.5333 4.01233 14.7667 4 15 4C16.1 4 17.0417 4.39167 17.825 5.175C18.6083 5.95833 19 6.9 19 8ZM3 18H15V17.2C15 17.0167 14.9543 16.85 14.863 16.7C14.771 16.55 14.65 16.4333 14.5 16.35C13.6 15.9 12.6917 15.5623 11.775 15.337C10.8583 15.1123 9.93333 15 9 15C8.06667 15 7.14167 15.1123 6.225 15.337C5.30833 15.5623 4.4 15.9 3.5 16.35C3.35 16.4333 3.22933 16.55 3.138 16.7C3.046 16.85 3 17.0167 3 17.2V18ZM9 10C9.55 10 10.021 9.804 10.413 9.412C10.8043 9.02067 11 8.55 11 8C11 7.45 10.8043 6.97933 10.413 6.588C10.021 6.196 9.55 6 9 6C8.45 6 7.97933 6.196 7.588 6.588C7.196 6.97933 7 7.45 7 8C7 8.55 7.196 9.02067 7.588 9.412C7.97933 9.804 8.45 10 9 10Z"
              fill="white"
            />
          </g>
        </svg>
        <p>Пользователи</p>
      </router-link>
    </li>
    <li>
      <router-link
        :class="[
          'sidebar-link',
          { 'sidebar-link--active': activePath === PAGE_HELP },
        ]"
        to="/home/help/"
      >
        <svg
          width="24"
          height="24"
          viewBox="0 0 24 24"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <mask
            id="mask0_1056_1748"
            style="mask-type: alpha"
            maskUnits="userSpaceOnUse"
            x="0"
            y="0"
            width="24"
            height="24"
          >
            <rect width="24" height="24" fill="#D9D9D9" />
          </mask>
          <g mask="url(#mask0_1056_1748)">
            <path
              d="M12 22L11.75 19H11.5C9.13333 19 7.125 18.175 5.475 16.525C3.825 14.875 3 12.8667 3 10.5C3 8.13333 3.825 6.125 5.475 4.475C7.125 2.825 9.13333 2 11.5 2C12.6833 2 13.7873 2.22067 14.812 2.662C15.8373 3.104 16.7377 3.71233 17.513 4.487C18.2877 5.26233 18.8957 6.16233 19.337 7.187C19.779 8.21233 20 9.31667 20 10.5C20 13.0333 19.2417 15.304 17.725 17.312C16.2083 19.3207 14.3 20.8833 12 22ZM11.475 15.975C11.7583 15.975 12 15.875 12.2 15.675C12.4 15.475 12.5 15.2333 12.5 14.95C12.5 14.6667 12.4 14.425 12.2 14.225C12 14.025 11.7583 13.925 11.475 13.925C11.1917 13.925 10.95 14.025 10.75 14.225C10.55 14.425 10.45 14.6667 10.45 14.95C10.45 15.2333 10.55 15.475 10.75 15.675C10.95 15.875 11.1917 15.975 11.475 15.975ZM10.75 12.8H12.25C12.25 12.3 12.3 11.95 12.4 11.75C12.5 11.55 12.8167 11.1833 13.35 10.65C13.65 10.35 13.9 10.025 14.1 9.675C14.3 9.325 14.4 8.95 14.4 8.55C14.4 7.7 14.1127 7.06233 13.538 6.637C12.9627 6.21233 12.2833 6 11.5 6C10.7667 6 10.15 6.204 9.65 6.612C9.15 7.02067 8.8 7.51667 8.6 8.1L10 8.65C10.0833 8.36667 10.2417 8.08733 10.475 7.812C10.7083 7.53733 11.05 7.4 11.5 7.4C11.95 7.4 12.2877 7.525 12.513 7.775C12.7377 8.025 12.85 8.3 12.85 8.6C12.85 8.88333 12.7667 9.13733 12.6 9.362C12.4333 9.58733 12.2333 9.81667 12 10.05C11.4167 10.55 11.0627 10.9457 10.938 11.237C10.8127 11.529 10.75 12.05 10.75 12.8ZM14 17V18.35C15.1833 17.35 16.1457 16.1793 16.887 14.838C17.629 13.496 18 12.05 18 10.5C18 8.68333 17.371 7.14567 16.113 5.887C14.8543 4.629 13.3167 4 11.5 4C9.68333 4 8.146 4.629 6.888 5.887C5.62933 7.14567 5 8.68333 5 10.5C5 12.3167 5.62933 13.8543 6.888 15.113C8.146 16.371 9.68333 17 11.5 17H14Z"
              fill="white"
            />
          </g>
        </svg>
        <p>Поддержка</p>
      </router-link>
    </li>
    <li>
      <router-link
        :class="[
          'sidebar-link',
          { 'sidebar-link--active': activePath === PAGE_SETTINGS },
        ]"
        to="/home/settings/"
      >
        <svg
          width="24"
          height="24"
          viewBox="0 0 24 24"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <mask
            id="mask0_1056_1736"
            style="mask-type: alpha"
            maskUnits="userSpaceOnUse"
            x="0"
            y="0"
            width="24"
            height="24"
          >
            <rect width="24" height="24" fill="#D9D9D9" />
          </mask>
          <g mask="url(#mask0_1056_1736)">
            <path
              d="M2 21V19H11V7.825C10.5667 7.675 10.1917 7.44167 9.875 7.125C9.55833 6.80833 9.325 6.43333 9.175 6H6L9 13C9 13.8333 8.65833 14.5417 7.975 15.125C7.29167 15.7083 6.46667 16 5.5 16C4.53333 16 3.70833 15.7083 3.025 15.125C2.34167 14.5417 2 13.8333 2 13L5 6H3V4H9.175C9.375 3.41667 9.73333 2.93733 10.25 2.562C10.7667 2.18733 11.35 2 12 2C12.65 2 13.2333 2.18733 13.75 2.562C14.2667 2.93733 14.625 3.41667 14.825 4H21V6H19L22 13C22 13.8333 21.6583 14.5417 20.975 15.125C20.2917 15.7083 19.4667 16 18.5 16C17.5333 16 16.7083 15.7083 16.025 15.125C15.3417 14.5417 15 13.8333 15 13L18 6H14.825C14.675 6.43333 14.4417 6.80833 14.125 7.125C13.8083 7.44167 13.4333 7.675 13 7.825V19H22V21H2ZM16.625 13H20.375L18.5 8.65L16.625 13ZM3.625 13H7.375L5.5 8.65L3.625 13ZM12 6C12.2833 6 12.521 5.90433 12.713 5.713C12.9043 5.521 13 5.28333 13 5C13 4.71667 12.9043 4.479 12.713 4.287C12.521 4.09567 12.2833 4 12 4C11.7167 4 11.4793 4.09567 11.288 4.287C11.096 4.479 11 4.71667 11 5C11 5.28333 11.096 5.521 11.288 5.713C11.4793 5.90433 11.7167 6 12 6Z"
              fill="white"
            />
          </g>
        </svg>
        <p>Настройки</p>
      </router-link>
    </li>
  </ul>
</template>

<script>
import Logo from '@/components/ui/icons/Logo';
import { PAGE_USERS, PAGE_HELP, PAGE_SETTINGS } from '@/constants/PageName';

export default {
  name: 'SideBar',
  components: {
    Logo,
  },
  data() {
    return {
      PAGE_USERS,
      PAGE_HELP,
      PAGE_SETTINGS,
    };
  },
  computed: {
    activePath() {
      return this.$route.path.split('/').filter(p => {
        return p != '';
      })[1];
    },
  },
  props: {
    activePage: String,
  },
};
</script>

<style lang="scss" scoped>
.auth-logo {
  width: 120px;
  height: 15px;
  margin-bottom: 0;
}
</style>
