<template>
  <div class="app" id="app">
    <template v-if="isAuthPage">
      <div class="app-content">
        <router-view></router-view>
      </div>
    </template>
    <template v-else>
      <SideBar />
      <div class="app-content">
        <Header :mail="getMail" />
        <div class="app-content--box">
          <Breadcrumbs class="breadcrumbs" />
          <router-view></router-view>
        </div>
      </div>
      <Modal v-if="isOpenModal">
        <component
          :is="componentModal"
          :data="dataModal"
          @closeModal="closeModal"
        />
      </Modal>
    </template>
  </div>
</template>

<script>
import SideBar from '@/components/SideBar';
import Header from '@/components/Header';
import Modal from '@/components/Modal';
import Breadcrumbs from '@/components/ui/Breadcrumbs';
import { loader } from 'vue-ui-preloader';

import { mapMutations, mapState } from 'vuex';

export default {
  data() {
    return {
      isAuthPage: false,
    };
  },
  mounted() {
    const LOCATION = window.location.pathname;

    this.checkPath(LOCATION);
  },
  computed: {
    ...mapState({
      token: state => state.token,
      nameModal: state => state.nameModal,
      isOpenModal: state => state.isOpenModal,
      dataModal: state => state.dataModal,
      isLoading: state => state.isLoading,
      mail: state => state.mail,
    }),
    componentModal() {
      if (this.nameModal) {
        return () => import(`./components/Modals/${this.nameModal}.vue`);
      }
      return null;
    },
    getMail() {
      if (!this.mail) {
        return window.localStorage.getItem('mail');
      }
      return this.mail;
    },
  },
  methods: {
    ...mapMutations(['setToken', 'closeModal']),
    checkPath(path) {
      if (path.match('auth')?.length) {
        this.isAuthPage = true;
      } else {
        this.isAuthPage = false;
      }
    },
  },
  watch: {
    '$route.path'(to) {
      this.checkPath(to);
    },
  },
  bedoreDestroy() {
    window.localStorage.removeItem('token');
  },
  components: {
    SideBar,
    Header,
    Modal,
    Breadcrumbs,
    loader,
  },
};
</script>

<style lang="scss">
.app {
  display: flex;
  &-content {
    width: 100%;
    &--box {
      width: 100%;
      height: 100%;
      height: calc(100vh - 70px);
      background: white;
      padding: 28px 65px;
    }
  }
}
</style>
