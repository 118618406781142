import Vue from 'vue';
import VueRouter from 'vue-router';

import Page404 from '../views/Page404';

Vue.use(VueRouter);

const routes = [
  {
    path: '/auth/',
    name: 'auth',
    component: () => import('../views/Auth'),
  },
  {
    path: '/home/',
    redirect: '/home/users/',
  },
  {
    path: '/home/users/',
    name: 'users',
    component: () => import('../views/UsersLists'),
  },
  {
    path: '/home/users/:id',
    name: 'user',
    component: () => import('../views/UserManage'),
  },
  {
    path: '/home/help/',
    name: 'help',
    component: () => import('../views/Help'),
  },
  {
    path: '/home/settings/',
    name: 'settings',
    component: () => import('../views/Settings'),
  },
  {
    path: '*',
    name: 'page 404',
    component: Page404,
  },
];

const router = new VueRouter({
  mode: 'history',
  routes,
});

router.beforeEach((routeTo, routeFrom, next) => {
  const token = localStorage.getItem('token');
  if (routeTo.name !== 'auth' && !token) next({ name: 'auth' });
  next();
});

export { router };
