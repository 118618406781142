import axios from 'axios';
import { HTTP_TOKEN } from '@/assets/js/http-client';

export default {
  actions: {
    getHelp({ commit }) {
      commit('startLodaing');
      axios
        .create({
          headers: {
            Authorization: `Bearer ${localStorage.getItem('token')}`,
          },
        })
        .get(`/admin/questions/`)
        .then(response => {
          commit('setQuestions', response.data.data);
          commit('stopLoading');
        })
        .catch(error => {
          console.log(error);
          const message = 'Не удалось загрузить данные пользователя';
          this.$app.$options.toast({
            message,
            title: 'Ошибка',
            type: 'error',
            timeout: 6000,
          });
          commit('stopLoading');
        });
    },
    createBlockQuestion({ commit }, data) {
      commit('startLodaing');
      HTTP_TOKEN.post('/admin/add-question/', data)
        .then(() => {
          data.successCallback();
          const message = 'Вопрос добавлен';
          this.$app.$options.toast({
            message,
            title: 'Успех',
            type: 'success',
            timeout: 6000,
          });
          commit('stopLoading');
        })
        .catch(error => {
          console.log(error);
          const message = 'Не удалось добавить вопрос';
          this.$app.$options.toast({
            message,
            title: 'Ошибка',
            type: 'error',
            timeout: 6000,
          });
          commit('stopLoading');
        });
    },
    editBlockQuestion({ commit }, data) {
      commit('startLodaing');
      HTTP_TOKEN.post('/admin/edit-question/', data)
        .then(() => {
          data.successCallback();
          const message = 'Вопрос отредактирован';
          this.$app.$options.toast({
            message,
            title: 'Успех',
            type: 'success',
            timeout: 6000,
          });
          commit('stopLoading');
        })
        .catch(error => {
          console.log(error);
          const message = 'Не удалось отредактировать вопрос';
          this.$app.$options.toast({
            message,
            title: 'Ошибка',
            type: 'error',
            timeout: 6000,
          });
          commit('stopLoading');
        });
    },
    deleteBlockQuestion({ commit }, data) {
      commit('startLodaing');
      HTTP_TOKEN.post('/admin/remove-question/', data)
        .then(() => {
          data.successCallback();
          const message = 'Вопрос удален';
          this.$app.$options.toast({
            message,
            title: 'Успех',
            type: 'success',
            timeout: 6000,
          });
          commit('stopLoading');
        })
        .catch(error => {
          console.log(error);
          const message = 'Не удалось удалить вопрос';
          this.$app.$options.toast({
            message,
            title: 'Ошибка',
            type: 'error',
            timeout: 6000,
          });
          commit('stopLoading');
        });
    },
  },
};
