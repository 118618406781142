import Vue from 'vue';
import Vuex from 'vuex';
import users from '@/store/users';
import help from '@/store/help';
import settings from '@/store/settings';
import { HTTP } from '@/assets/js/http-client';

Vue.use(Vuex);

const store = new Vuex.Store({
  modules: {
    users,
    help,
    settings,
  },

  state: {
    isLoading: false,
    isOpenModal: false,
    nameModal: null,
    dataModal: null,
    settings: null,
    mail: '',
    users: [],
    user: null,
    questions: null,
  },

  mutations: {
    clearStateUser(state) {
      state.user = null;
    },
    setUser(state, user) {
      state.user = user;
    },
    setSettings(state, settings) {
      state.settings = {};
      Object.keys(settings).map(key => {
        if (key !== 'updated_at' && key !== 'created_at') {
          state.settings[key] = settings[key];
        }
      });
    },
    setUsers(state, users) {
      state.users = users;
    },
    setMail(state, mail) {
      localStorage.setItem('mail', mail);
      state.mail = mail;
    },
    startLodaing(state) {
      state.isLoading = true;
    },
    stopLoading(state) {
      state.isLoading = false;
    },
    clearState(state) {
      state.token = null;
    },
    openModal(state, data) {
      state.isOpenModal = true;
      state.nameModal = data.nameModal;
      state.dataModal = data.dataModal;
    },
    closeModal(state) {
      state.isOpenModal = false;
      state.nameModal = null;
      state.dataModal = null;
    },
    setQuestions(state, questions) {
      state.questions = questions;
    },
  },

  actions: {
    login({ commit }, data) {
      commit('startLodaing');
      HTTP.post(`/admin/login/`, data)
        .then(async response => {
          localStorage.setItem('token', response.data.data.token);
          commit('setMail', data.email);
          commit('stopLoading');
          const message = 'Вы в системе';
          this.$app.$options.toast({
            message,
            title: 'Успех',
            type: 'success',
            timeout: 6000,
          });
          this.$app.$router.push('/home/settings/');
        })
        .catch(error => {
          console.log(error);
          const message = 'Неправильные учетные данные';
          this.$app.$options.toast({
            message,
            title: 'Ошибка',
            type: 'error',
            timeout: 6000,
          });
          commit('stopLoading');
        });
    },
  },
});

export { store };
