<template>
  <div class="header">
    <div class="header-mail">
      <p>{{ mail }}</p>
    </div>
    <div class="header-exit">
      <router-link to="/auth/">
        <svg
          width="24"
          height="24"
          viewBox="0 0 24 24"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <mask
            id="mask0_1056_1513"
            style="mask-type: alpha"
            maskUnits="userSpaceOnUse"
            x="0"
            y="0"
            width="24"
            height="24"
          >
            <rect width="24" height="24" fill="#D9D9D9" />
          </mask>
          <g mask="url(#mask0_1056_1513)">
            <path
              d="M9 11H17.175L14.625 8.45L16 7L21 12L16 17L14.625 15.55L17.175 13H9V11ZM12 3V5H5V19H12V21H5C4.45 21 3.979 20.8043 3.587 20.413C3.19567 20.021 3 19.55 3 19V5C3 4.45 3.19567 3.979 3.587 3.587C3.979 3.19567 4.45 3 5 3H12Z"
              fill="#A1ADB2"
            />
          </g>
        </svg>
      </router-link>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Header',
  props: {
    mail: String,
  },
};
</script>

<style lang="scss" scoped></style>
