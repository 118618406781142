<template>
  <svg
    width="325"
    height="43"
    viewBox="0 0 325 43"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M18.8145 2.74829C21.6976 2.74829 24.3747 3.45873 26.8458 4.8796C29.317 6.30046 31.2827 8.22612 32.7242 10.6566C34.1657 13.087 34.8771 15.7605 34.8771 18.677C34.8771 21.5187 34.1657 24.1548 32.7429 26.5852C31.3201 29.0157 29.3919 30.96 26.9581 32.3996C24.5244 33.8392 21.866 34.5496 18.983 34.5496V42.0092H0V2.74829H18.8145Z"
      fill="white"
    />
    <path
      d="M121.121 2.74829L106.407 22.3787L121.121 42.0652H82.5938V2.74829H121.121Z"
      fill="white"
    />
    <path
      d="M155.492 2.69214V15.9287H147.349V17.6113H155.492V27.0339H147.349V28.7165H155.492V41.953H122.918V2.69214H155.492Z"
      fill="white"
    />
    <path
      d="M177.677 2.74829C180.56 2.74829 183.237 3.45873 185.69 4.8796C188.142 6.30046 190.089 8.24481 191.531 10.6939C192.972 13.1431 193.684 15.8166 193.684 18.7331C193.684 21.2383 193.141 23.5752 192.055 25.7439C190.969 27.9126 189.472 29.7448 187.562 31.2405L193.347 42.0652H158.807V2.74829H177.677Z"
      fill="white"
    />
    <path
      d="M214.801 2.69214V17.1626H216.486V2.69214H235.3V41.953H216.486V27.4826H214.801V41.953H195.986V2.69214H214.801Z"
      fill="white"
    />
    <path d="M260.293 0L285.117 42.0091H235.469L260.293 0Z" fill="white" />
    <path
      d="M284.947 1.23389L304.604 16.0969L324.261 1.23389L324.374 42.0091H285.06L284.947 1.23389Z"
      fill="white"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M47.3597 3.07364C44.2761 3.50635 41.3226 5.20916 39.3694 7.68042C36.6965 11.0624 36.2532 15.6369 38.2019 19.7295C39.9792 23.4622 46.2275 30.4016 56.6572 40.2262C57.1304 40.672 57.7377 41.2534 58.0067 41.5183L58.4957 42L59.0344 41.472C59.3307 41.1817 60.326 40.232 61.2462 39.3618C70.2001 30.8935 76.0832 24.4476 78.2812 20.6972C80.1589 17.4933 80.5192 13.7469 79.2582 10.5386C78.4588 8.50485 77.1758 6.84217 75.3477 5.47097C74.6644 4.95848 74.0773 4.60248 73.2119 4.17582C72.358 3.75487 71.7732 3.53995 70.8715 3.31572C69.9428 3.08482 69.2256 3 68.2013 3C66.4449 3 64.9954 3.31611 63.5884 4.00598C61.8665 4.85028 60.3046 6.21048 58.927 8.06546C58.7117 8.35536 58.5198 8.5925 58.5005 8.5925C58.4812 8.5925 58.2703 8.33218 58.0318 8.014C56.6836 6.21552 55.1253 4.85805 53.4544 4.02657C52.4942 3.54869 51.4214 3.23056 50.2278 3.06956C49.5981 2.98464 47.9775 2.98694 47.3597 3.07364Z"
      fill="#8E9CA2"
    />
  </svg>
</template>

<script>
export default {
  name: 'Logo',
};
</script>

<style scoped></style>
