export const PAGE_USERS = 'users';
export const PAGE_HELP = 'help';
export const PAGE_SETTINGS = 'settings';
export const PAGE_HOME = 'home';

export const RusLabelPage = [
  {
    route: PAGE_USERS,
    label: 'Пользователи',
  },
  {
    route: PAGE_HOME,
    label: 'Главная',
  },
  {
    route: PAGE_HELP,
    label: 'Помощь',
  },
  {
    route: PAGE_SETTINGS,
    label: 'Настройки',
  },
];
