import Vue from 'vue';
import App from './App';

import { router } from './router';
import { store } from './store';

import VueNotifications from 'vue-notifications';
import iziToast from 'izitoast';
import { loader } from 'vue-ui-preloader';

import 'izitoast/dist/css/iziToast.min.css';
import '@/assets/scss/main.scss';

//Функция для уведомлений
function toast({ title, message, type, timeout }) {
  if (type === VueNotifications.types.warn) type = 'warning';
  return iziToast[type]({ title, message, timeout });
}

const options = {
  success: toast,
  error: toast,
  info: toast,
  warn: toast,
};

Vue.use(VueNotifications, options);
Vue.use(loader);

const app = new Vue({
  el: '#app',
  render: h => h(App),
  router,
  store,
  toast,
  components: {
    loader,
  },
});

store.$app = app;
