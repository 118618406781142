<template>
  <h1 class="page404">Error 404: Page not found</h1>
</template>

<script>
export default {
  name: 'Page404',
};
</script>

<style lang="scss" scoped></style>
