<template>
  <div class="modal">
    <div @click="closeModal" class="modal--drop"></div>
    <div class="modal-content">
      <slot></slot>
    </div>
  </div>
</template>

<script>
import { mapMutations, mapState } from 'vuex';

export default {
  name: 'Modal',
  mounted() {
    window.addEventListener('keydown', e => {
      if (this.isOpenModal) {
        if (e.key == 'Escape' || e.key == 'Esc' || e.keyCode == 27) {
          this.closeModal();
        }
      }
    });
  },
  methods: {
    ...mapMutations(['closeModal']),
  },
  computed: {
    ...mapState({
      isOpenModal: state => state.isOpenModal,
    }),
  },
};
</script>

<style lang="scss" scoped></style>
