import axios from 'axios';
import { HTTP_TOKEN } from '@/assets/js/http-client';

export default {
  actions: {
    getUsers({ commit }) {
      commit('startLodaing');
      axios
        .create({
          headers: {
            Authorization: `Bearer ${localStorage.getItem('token')}`,
          },
        })
        .get(`/admin/users/`)
        .then(response => {
          commit('setUsers', response.data.data);
          commit('stopLoading');
        })
        .catch(error => {
          console.log(error);
          const message = 'Не удалось загрузить настройки';
          this.$app.$options.toast({
            message,
            title: 'Ошибка',
            type: 'error',
            timeout: 6000,
          });
          commit('stopLoading');
        });
    },
    searchUser({ commit }, data) {
      const getParametres = [];
      for (let key in data) {
        if (data[key] || data[key] === 0) {
          getParametres.push(`${key}=${data[key]}`);
        }
      }
      commit('startLodaing');
      axios
        .create({
          headers: {
            Authorization: `Bearer ${localStorage.getItem('token')}`,
          },
        })
        .get(`/admin/users/?${getParametres.join('&')}`)
        .then(response => {
          commit('setUsers', response.data.data);
          commit('stopLoading');
        })
        .catch(error => {
          console.log(error);
          const message = 'Не удалось загрузить настройки';
          this.$app.$options.toast({
            message,
            title: 'Ошибка',
            type: 'error',
            timeout: 6000,
          });
          commit('stopLoading');
        });
    },
    getUser({ commit }, data) {
      commit('startLodaing');
      axios
        .create({
          headers: {
            Authorization: `Bearer ${localStorage.getItem('token')}`,
          },
        })
        .get(`/admin/user/${data}`)
        .then(response => {
          commit('setUser', response.data.data);
          commit('stopLoading');
        })
        .catch(error => {
          console.log(error);
          const message = 'Не удалось загрузить данные пользователя';
          this.$app.$options.toast({
            message,
            title: 'Ошибка',
            type: 'error',
            timeout: 6000,
          });
          commit('stopLoading');
        });
    },
    banUser({ commit }, data) {
      commit('startLodaing');
      HTTP_TOKEN.post('/admin/ban-user/', data)
        .then(() => {
          data.successCallback();
          const message = `Пользователь ${
            data.type ? 'забанен' : 'восстановлен'
          }`;
          this.$app.$options.toast({
            message,
            title: 'Успех',
            type: 'success',
            timeout: 6000,
          });
          commit('stopLoading');
        })
        .catch(error => {
          console.log(error);
          const message = 'Не удалось забанить пользователя';
          this.$app.$options.toast({
            message,
            title: 'Ошибка',
            type: 'error',
            timeout: 6000,
          });
          commit('stopLoading');
        });
    },
  },
};
